import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import userStoreModule from '@/views/apps/user/userStoreModule';
import reportStoreModule from '@/views/apps/report/reportStoreModule';
import jobStoreModule from '@/views/apps/job/jobStoreModule';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import placementStoreModule from '@/views/apps/placement/placementStoreModule';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,

    userStoreModule,
    reportStoreModule,
    jobStoreModule,
    rosterStoreModule,
    studentStoreModule,
    placementStoreModule,
  },
  strict: process.env.DEV,
});
