import Vue from 'vue';
import ability from './ability';

Vue.mixin({
  name: 'Can',
  methods: {
    $can: (permission, legacy) => {
      if (legacy) {
        console.log(`LEGACY `, permission, legacy);
        return true;
      }

      return ability.can(permission);
    },
  },
});
