import { Workbox } from 'workbox-window';

let wb = null;

if ('serviceWorker' in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  wb.addEventListener('controlling', () => {
    window.location.reload();
  });

  // wb.addEventListener('fetch', function(event) {
  //   event.respondWith(async function() {
  //     try{
  //       var res = await fetch(event.request);
  //       var cache = await caches.open('cache');
  //       cache.put(event.request.url, res.clone());
  //       return res;
  //     }
  //     catch(error){
  //       return caches.match(event.request);
  //     }
  //   }());
  // });

  wb.register({ scope: '.' }).then(
    function (registration) {
      // Registration was successful
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    },
    function (err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    }
  );
}

export default wb;
