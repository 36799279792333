import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('groups', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('users', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id, ability = false, queryParams = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`users/${id}`, { params: { ability, ...queryParams } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('users', userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    resetUserPassword(ctx, { userId, password = undefined }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`users/${userId}/password`, { password })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
