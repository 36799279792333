import Vue from 'vue';
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.component(FeatherIcon.name, FeatherIcon);

/* add icons to the library */
library.add(fas);
library.add(fab);
library.add(far);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);
