export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    // component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    redirect: { name: 'apps-students-list' },
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    // component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    redirect: { name: 'apps-students-list' },
  },
];
