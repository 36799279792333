import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import { canNavigate } from '@/libs/acl/routeProtection';
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils';
import apps from './routes/apps';
import dashboard from './routes/dashboard';
import uiElements from './routes/ui-elements/index';
import pages from './routes/pages';
import chartsMaps from './routes/charts-maps';
import formsTable from './routes/forms-tables';
import others from './routes/others';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '/nuke',
      component: () => import('../views/apps/Nuke'),
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!(await canNavigate(to))) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' });

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next('/');
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }

  if (window.WsController) {
    window.WsController.onRouteChange({
      to: to.path,
      from: from.path,
    });
  }

  try {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    if (userData && userData.id) {
      Vue.$gtag.set({ user_id: userData.id });
    }
  } catch (e) {
    // Bro sometimes it just doesn't work ok
  }
});

export default router;
