import Vue from 'vue';

// axios
import axios from 'axios';
import { v4 } from 'uuid';

/**
 * @returns {string}
 */
const getDeviceId = (LS_KEY = 'xdid') => {
  if (!localStorage.getItem(LS_KEY)) {
    localStorage.setItem(LS_KEY, v4());
  }

  return localStorage.getItem(LS_KEY);
};

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL || 'https://mgmt-api.intellectualpoint.com/v1',

  // timeout: 1000,
  headers: { 'x-device-id': getDeviceId() },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
