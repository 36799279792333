import ability from './ability';

export const canNavigate = async (to) => {
  return (
    await Promise.all(
      to.matched.map((route) => {
        return ability.wcan(route.meta.permission);
      })
    )
  ).some((a) => a);
};

export const _ = undefined;
